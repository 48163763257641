import { useDispatch, useSelector } from "react-redux"

import { increment } from "./Demo";

const MyComponent = () =>{
    // const {inc}
    const storeData = useSelector((state)=>{
            console.log('state',state);
            return state
            
        })

    const dispatch = useDispatch()
    return (
        <div>
            MyComponent..... {storeData}
            <button 
            onClick={()=>dispatch(increment())}
            >INC</button>
        </div>
    )
}

export default MyComponent