import logo from './logo.svg';
import './App.css';
import './assets/scss/landing.css';

import Routes from './router/Index.js'
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/AuthContext.js';
import Demo from './demo/Demo.js';

function App() {
  useEffect(() => {
    document.title = 'Growwup Digital Solution';
  }, []);

  // return  <Demo />
  return (
    <>
      <ToastContainer />
      <AuthProvider >
        <Routes />
      </AuthProvider>
    </>

  );
}

export default App;
