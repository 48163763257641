
import { io } from "socket.io-client";
let socket = null;

// Initialize socket with a userId (temp or actual)
export const initializeSocket = (userId) => {
    if (socket) {
        console.log("Socket already initialized");
        return socket;
    }

    socket = io(process.env.REACT_APP_API_BASE_URL, { query: { userId } });

    socket.on("publicNotification", (publicNotificationData) => {
        console.log("publicNotificationData", publicNotificationData);
    })
    // socket.on("newMessage", (newMessage) => {
    //     console.log("newMessage", newMessage);
    // })
    console.log('new socket connection created...');

    return socket;
};

// Disconnect the current socket instance
export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        socket = null;
        console.log("Socket disconnected");
    }
};

// Reconnect the socket with a new userId (useful after login)
export const reconnectSocket = (userId) => {
    disconnectSocket(); // Disconnect any existing socket connection
    return initializeSocket(userId); // Reinitialize with new userId
};

// Access the existing socket instance (if already initialized)
export const getSocket = () => socket;
