import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';

import Header from '../../Website/Header';
import Footer from '../Footer';
// import io from 'socket.io-client';

import { useNavigate } from 'react-router-dom';
import { deleteBroadcast, getBroadcastList } from '../../../api/broadcastApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loader from '../../common/Loader';
import { formateDateTime } from '../../../utils/dateTimeManage';
import Pagination from '../../common/Pagination';
// const socket = io(process.env.REACT_APP_API_BASE_URL);

function BroadcastList() {
    const navigate = useNavigate();
    const [broadcastList, setBroadcastList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { logoutUser } = useAuthContext();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [finalSearchText, setFinalSearchText] = useState('')
    const [timerId, setTimerId] = useState(null);
    const handleBroadcastList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: itemsPerPage,
                skip: (currentPage - 1) * itemsPerPage,
                name: finalSearchText
            }
            const responceData = await getBroadcastList(postData);
            if (responceData?.result?.data) {
                setTotalItems(responceData.result.count)
                setBroadcastList(responceData.result.data);
            } else {
                setTotalItems(0)
                setBroadcastList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Broadcast list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    // Event handler for input change
    const handleSearchChange = (event) => {
        const text = event.target.value;
        setSearch(text);

        // Debouncing logic
        if (timerId) {
            clearTimeout(timerId);
        }
        const newTimerId = setTimeout(() => {
            setFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setTimerId(newTimerId);
    };

    useEffect(() => {
        handleBroadcastList();
    }, [currentPage, finalSearchText])

    useEffect(() => {
        // console.log('trueeee')
        // socket.on('messageStatus', (msg) => {
        //     console.log('messageStatus', msg)

        // });
        // socket.on('message', (msg) => {
        //     console.log('message', msg)

        // });
        // socket.on('templateStatus', (msg) => {
        //     console.log('templateStatus', msg)

        // });

    }, [])

    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">


                <h5 className="title">Manage Broadcast</h5>

                <div className="card border-0 mb-4">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-auto">
                                <i className="bi bi-shop h5 avatar avatar-40 bg-light-theme rounded"></i>
                            </div>
                            <div className="col mb-2">
                                <button onClick={(e) => navigate('/create-broadcast')} className="btn btn-theme btn-md" >Create Broadcast</button>
                            </div>

                            <div className="col-12 col-md-auto">
                                <div className="input-group ">
                                    <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Search..." value={search} onChange={handleSearchChange} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card-body p-0">
                        <table className="table footable" data-show-toggle="true">
                            <thead>
                                <tr className="text-muted">
                                    <th>Sr No.</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Created Date</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {(broadcastList.length > 0) ? (
                                    broadcastList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td>{formateDateTime(item.createdAt)}</td>
                                            <td>
                                                <Icon.Eye size={18} color="blue" className='cursor-pointer' onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(`/broadcast-detail/${item._id}`)
                                                }} />

                                            </td>
                                        </tr>

                                    ))
                                ) : (
                                    <tr key="0" >
                                        <th colSpan="20" style={{ textAlign: 'center' }}>No data available..</th>
                                    </tr>
                                )}



                            </tbody>
                        </table>
                        {totalItems > 0 &&
                            <Pagination
                                currentPage={currentPage}
                                totalItems={totalItems}
                                itemsPerPage={itemsPerPage}
                                setCurrentPage={setCurrentPage}
                            />
                        }
                    </div>

                </div>


            </div>


        </main>
        <Footer />

    </>)
}

export default BroadcastList;