import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Icon from 'react-bootstrap-icons';
// import io from 'socket.io-client';
import { toast } from 'react-toastify';
import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { qrBroadcastInitialValues, titleSchema, } from '../../../utils/validationSchema';
import { createSession, sendBroadcastByWeb, deleteSession } from '../../../api/broadcastApi';
import { getGroupList } from '../../../api/groupApi';
import { getUserDetails } from '../../../api/userApi'
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import { getTemplateList } from '../../../api/templateApi';
// const socket = io(process.env.REACT_APP_API_BASE_URL);

function CreateBroadcast() {
    const [loading, setLoading] = useState(false);
    const { userData } = useAuthContext();
    const navigate = useNavigate();
    // null not requested for qr yet
    // RequestedForQR, RecievedQR
    const [qrProgress, setQrProgress] = useState(localStorage.getItem('qrProgress') ? localStorage.getItem('qrProgress') : null)
    const [qrCodeImg, setQrCodeImg] = useState(null)
    const [qrUserDetails, setQrUserDetails] = useState(localStorage.getItem('userDetailsToQr') ? JSON.parse(localStorage.getItem('userDetailsToQr')) : null)
    const { logoutUser } = useAuthContext();
    const [groupList, setGroupList] = useState([]);
    const [filePreview, setFilePreview] = useState(null);
    // const [userdetails, setUserdetails] = useState({});
    const [templateList, setTemplateList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)



    const handleLoadQRCode = async () => {
        // alert(selectedGroup)
        // if (selectedGroup == null || selectedTemplate == null) {
        //     toast.warning('Please create a group and template first');
        // } else {



        setLoading(true);
        try {
            let responceData = await createSession();
            toast.success('Please wait QR is loading..');
            setQrProgress("RequestedForQR")
        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create group failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
        // }
    }
    const handleGroupList = async () => {
        setLoading(true);
        try {
            const responceData = await getGroupList();
            setGroupList(responceData.result.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Group list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleUserdetails = async () => {
        setLoading(true);
        try {
            const responceData = await getUserDetails();

            // setUserdetails(responceData.data.qrSession)
            if (responceData.data.qrSession) {
                console.log('responceData', responceData.data.qrUserDetails.userDetails);

                localStorage.setItem('userDetailsToQr', JSON.stringify(responceData.data.qrUserDetails.userDetails))
                setQrUserDetails(responceData.data.qrUserDetails.userDetails)

                setQrProgress("ConnectedToQR")
                setQrCodeImg('')

            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'This account not registerd';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleTemplateList = async () => {
        setLoading(true);
        try {
            const responceData = await getTemplateList();
            setTemplateList(responceData.result.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Template list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleSubmit = async () => {
        setLoading(true);
        try {
            let postData = {
                groupId: selectedGroup._id,
                templateId: selectedTemplate._id,
                sessionId: userData._id,
                title: selectedTemplate.name,
                description: selectedTemplate.description,
                boradcastType: 1,
                createdby: userData._id,
            }
            console.log('postData', postData);
            let responceData = await sendBroadcastByWeb(postData);
            toast.success('Broadcast send successful!');
            navigate('/broadcast')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create broadcast failed';
            if (errorMessage === "Session not found.") {
                setQrProgress(null)
                toast.error("Your whatsapp session timeout. Please again scan QR..");
            } else {
                toast.error(errorMessage);
            }
        } finally {
            setLoading(false);
        }
    };
    const logoutQRCode = async () => {
        try {
            let responceData = await deleteSession();
            toast.success('Session distory successfully.');
            setQrProgress("")
            setQrUserDetails("")
            setQrCodeImg("")

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create group failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        console.log('trueeee')

        handleGroupList();
        handleTemplateList();
        handleUserdetails();
        // socket.on('GenerateQr', (msg) => {
        //     console.log('GenerateQr', msg);
        //     if (userData._id == msg.orgId && msg.qr) {
        //         setQrProgress("RecievedQR")
        //         setQrCodeImg(msg.qr)

        //     } else {
        //         if (msg.generateQrStatus == false) {
        //             console.log('stap 2');

        //             setQrProgress('')
        //             setQrCodeImg('')
        //             setQrUserDetails("")


        //         }
        //     }
        // });
        // socket.on('qrScaned', (msg) => {

        //     if (msg.status) {
        //         console.log('qrScaned', msg);
        //         let userDetails = msg.userDetails;
        //         setQrUserDetails(userDetails)
        //         setQrProgress("ConnectedToQR")
        //         localStorage.setItem("userDetailsToQr", JSON.stringify(userDetails));
        //         toast.success('QR connected to whatsapp account!');
        //     } else {
        //         setQrUserDetails('')
        //         setQrProgress("")
        //         localStorage.setItem("userDetailsToQr", '');
        //         toast.error('Please scan a valid account');
        //         logoutQRCode()

        //     }
        // });
        // console.log('userData', userData);
        // socket.on('messageStatus', (msg) => {
        //     console.log('messageStatus', msg)

        // });
        // socket.on('message', (msg) => {
        //     console.log('message', msg)

        // });
        // socket.on('templateStatus', (msg) => {
        //     console.log('templateStatus', msg)

        // });

    }, [])

    useEffect(() => {
        localStorage.setItem('qrProgress', qrProgress)

    }, [qrProgress])

    useEffect(() => {
        if (selectedTemplate && selectedTemplate.imageUrl && selectedTemplate.imageUrl != "") {
            setFilePreview(process.env.REACT_APP_API_IMG_URL + selectedTemplate.imageUrl);
        } else {
            setFilePreview(null)
        }
    }, [selectedTemplate])
    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">
                <div className="row top-row">
                    <div className="col-12 col-md-3 col-lg-3">
                        <div className="card bg-white border-0 text-center mb-4">
                            <div className="card-body p-4 px-lg-5">
                                {qrProgress == "ConnectedToQR" &&
                                    <div className='qr-div-logout'>
                                        <Icon.BoxArrowInRight onClick={(e) => {
                                            e.stopPropagation();
                                            logoutQRCode();
                                        }} className='bi display-4 text-theme mb-2 d-block' style={{ "width": "40px", "color": "red" }} />
                                    </div>
                                }
                                <div className='qr-div-h'>
                                    <Icon.QrCode className='bi display-4 text-theme mb-2 d-block' />
                                </div>

                                <h4>QR</h4>
                                <p className="text-secondary">Scan this QR code to join our broadcast!</p>

                                {(qrProgress === null || qrProgress === 'null') && (
                                    <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleLoadQRCode();
                                    }} className="btn btn-theme btn-md" > Load QR Code</button>
                                )}
                            </div>
                        </div>
                        {qrProgress !== null && qrProgress !== 'null' &&
                            <div className="card bg-white border-0 text-center mb-4">
                                <div className="card-body ">
                                    <div className="card border-0 mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                {(qrProgress == "RequestedForQR") || (qrProgress == "RecievedQR" && qrCodeImg == null) ? <Loader /> :
                                                    qrProgress == "RecievedQR" ? (<img src={qrCodeImg} alt="" />) :
                                                        qrProgress == "ConnectedToQR" && qrUserDetails !== null ? (
                                                            <div className="col-12  border-left-dashed">
                                                                <div className="row align-items-center mb-2">
                                                                    <div className="col">
                                                                        <h6 className="title">QR User Details:</h6>
                                                                    </div>

                                                                </div>
                                                                <ul className="list-group list-group-flush bg-none">
                                                                    <li className="list-group-item text-secondary">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <i className="bi bi-check-circle text-muted"></i>
                                                                            </div>
                                                                            <div className="col-auto ps-0">
                                                                                {qrUserDetails.name}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item text-secondary">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <i className="bi bi-check-circle text-muted"></i>
                                                                            </div>
                                                                            <div className="col-auto ps-0">
                                                                                {qrUserDetails.phone}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item text-secondary">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <i className="bi bi-check-circle text-muted"></i>
                                                                            </div>
                                                                            <div className="col-auto ps-0">
                                                                                Broadcast will sent from this number.
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                    <li className="list-group-item text-secondary">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <i className="bi bi-check-circle text-muted"></i>
                                                                            </div>
                                                                            <div className="col-auto ps-0">
                                                                                Next step select broadcasting group.
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item text-secondary">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <i className="bi bi-check-circle text-muted"></i>
                                                                            </div>
                                                                            <div className="col-auto ps-0">
                                                                                Then select template which you want to broadcast.
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            <button onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleLoadQRCode();
                                                            }} className="btn btn-theme btn-md" >{qrUserDetails} Load QR Code</button>
                                                        )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>


                    <div className="col-12 col-md-3 col-lg-3">
                        <div className="card bg-white border-0 text-center mb-4">
                            <div className="card-body p-4 px-lg-5">
                                <div className='qr-div-h'>
                                    <Icon.CollectionFill className='bi display-4 text-theme mb-2 d-block' />
                                </div>
                                <h4>Group</h4>
                                <p className="text-secondary">Create and select a group for broadcast.</p>
                            </div>
                        </div>
                        {(qrProgress == "ConnectedToQR" && qrUserDetails !== null) &&
                            (<div className="card bg-white border-0 text-center mb-4 ">
                                <div className="card-body  ">
                                    <div className="card border-0 mb-4">
                                        <div className="card-body">
                                            <div className="row">


                                                <div className="col-12  border-left-dashed">

                                                    <ul className="list-group list-group-flush bg-none">
                                                        <li className="list-group-item text-secondary">
                                                            {(groupList.length > 0) ? (
                                                                groupList.map((item, index) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-auto">
                                                                            <input type="radio" name='group' id={`group_id_${item._id}`} value={item._id} className='form-check-input' onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                setSelectedGroup(item)
                                                                            }} />&nbsp;
                                                                            <label className='cursor-pointer' htmlFor={`group_id_${item._id}`}> {item.name}</label>
                                                                        </div>
                                                                        <div className="col-auto ps-0">[Contacts - {item.totalContact}]</div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="row">
                                                                    <p>    No data available.. </p>
                                                                </div>

                                                            )}
                                                        </li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                    <div className={selectedTemplate ? 'col-12 col-md-3 col-lg-3' : 'col-12 col-md-6 col-lg-6'}>
                        <div className="card bg-white border-0 text-center mb-4">
                            <div className="card-body p-4 px-lg-5">
                                <div className='qr-div-h'>
                                    <Icon.FileEarmarkRichtext className='bi display-4 text-theme mb-2 d-block' />
                                </div>
                                <h4>Templete</h4>
                                <p className="text-secondary">Select the template to send broadcast.</p>
                            </div>
                        </div>
                        {(qrProgress == "ConnectedToQR" && qrUserDetails !== null && selectedGroup !== null) &&
                            (<div className="card bg-white border-0 text-center mb-4 ">
                                <div className="card-body  ">
                                    <div className="card border-0 mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12  border-left-dashed">
                                                    <ul className="list-group list-group-flush bg-none">
                                                        <li className="list-group-item text-secondary">
                                                            {(templateList.length > 0) ? (
                                                                templateList.map((item, index) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-auto">
                                                                            <input type="radio" name='template' id={`template_id_${item._id}`} value={item._id} className='form-check-input' onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                setSelectedTemplate(item)
                                                                                console.log('item', item);
                                                                            }} />&nbsp;
                                                                            <label className='cursor-pointer' htmlFor={`template_id_${item._id}`}> {item.name}</label>
                                                                        </div>

                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="row">
                                                                    <p>    No data available.. </p>
                                                                </div>

                                                            )}
                                                        </li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }
                        {/* {(qrProgress == "ConnectedToQR" && qrUserDetails !== null && selectedGroup !== null && selectedTemplate !== null) &&
                            (<div className="card bg-white border-0 text-center mb-4 ">
                                <div className="card-body  ">
                                    <div className="card border-0 mb-4">
                                        <div className="card-body">
                                            <div className="row">


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        } */}
                    </div>

                    <div className="col-12 col-md-3 col-lg-3">
                        {selectedTemplate &&
                            <div className="col-12 " style={{ "height": "100%" }}>

                                <section className="msger" >
                                    <header className="msger-header">
                                        <div className="msger-header-title">
                                            <i className="fas fa-comment-alt"></i> Chat Preview
                                        </div>
                                        <div className="msger-header-options">
                                            <span><i className="fas fa-cog"></i></span>
                                        </div>
                                    </header>

                                    <main className="msger-chat">
                                        <div className="msg left-msg">
                                            {/* <div

                                                                    ><Icon.PersonBoundingBox className="msg-img" /></div> */}

                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    {/* <div className="msg-info-name">BOT</div> */}
                                                    {/* <div className="msg-info-time">12:45</div> */}
                                                </div>
                                                {selectedTemplate.imageUrl != null && selectedTemplate.imageUrl != '' && selectedTemplate.imageUrl != undefined && (
                                                    <>
                                                        <img src={`http://13.127.5.158/growwup/uploads/${selectedTemplate.imageUrl}`} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                                        <br />
                                                    </>
                                                )}
                                                {/* <img src="http://localhost/uploads/Screenshot 2023-07-30 235410.png" /> */}
                                                <div className="msg-text" style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
                                                    {filePreview && (
                                                        <>
                                                            <img src={filePreview} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                                            <br />
                                                        </>
                                                    )}
                                                    {selectedTemplate.description}
                                                </div>
                                            </div>
                                        </div>
                                    </main>

                                    <form className="msger-inputarea">
                                        <input type="text" className="msger-input" placeholder="Click the send button..." disabled />
                                        <button type="button" className="msger-send-btn" onClick={(e) => {
                                            e.stopPropagation();
                                            handleSubmit();
                                        }} >Send</button>
                                    </form>
                                </section>
                                {/* <div className="mb-2">

                                                            <div className="form-group mb-3 position-relative check-valid">
                                                                <div className="input-group input-group-lg">
                                                                    <div className="form-floating">
                                                                        <textarea readOnly rows="10" cols="50" className="form-control border-start-0" value={} onChange={(e) => {
                                                                            e.stopPropagation();
                                                                        }} />
                                                                        <label>Description</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-theme" type="button"  >Save</button>
                                                        </div> */}
                            </div>
                        }

                    </div>

                </div>




            </div>




        </main >
        <Footer />

    </>)
}

export default CreateBroadcast;