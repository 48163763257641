
import { configureStore, createSlice } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import MyComponent from './MyComponent'
////////////// Reducer ToolKit ////////////////////////////

const counterSlice = createSlice({
    name: "counter",
    initialState: 5,
    reducers: {
        increment: (state) => state + 1,
        decrement: (state) => state - 1
    }
})
export  const { increment, decrement } = counterSlice.actions
const store = configureStore({
    reducer: counterSlice.reducer
})

// console.log('counterSlice', counterSlice);
// console.log('store', store);
// console.log('Provider', Provider);



const Demo = () => {
    return (
        <div>
            Demo...
            <Provider store={store}>
                <MyComponent />
            </Provider>
        </div>
    )
}
///////////////////// HOC//////////////////////////////
// import MyComponent from "./MyComponent";
// import WithLoader from "./WithLoader";

// const Demo = () =>{
//     const EnhancedComponent = WithLoader(MyComponent);
//     return (
//         <div>
//             Demo...
//             <EnhancedComponent isLoading={true} data={{otherInfo:"yes"}} />
//             <EnhancedComponent isLoading={false} data={{otherInfo:"yes"}} />
//         </div>
//     )
// }

export default Demo;