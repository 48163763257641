import { createContext, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { reconnectSocket, initializeSocket } from "../utils/socket";

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

const channel = new BroadcastChannel('auth-channel');

export const AuthProvider = ({ children }) => {
    const [userData, setUserData] = useState(
        localStorage.getItem('growwupInfo') && JSON.parse(localStorage.getItem('growwupInfo')).token.authToken ? jwtDecode(JSON.parse(localStorage.getItem('growwupInfo')).token.authToken) : null
    );
    const storeUserDataInContext = (userToken) => {
        const decodedData = jwtDecode(userToken.authToken);
        setUserData((prevData) => decodedData);

        console.log('storeUserDataInContext:userData', userData);

        // reconnect socket 
        channel.postMessage({ status: 'login' });
        connectSocketAuth({ action: 'reconnectSocket', userData: decodedData })
    }

    const logoutUser = () => {
        // localStorage.removeItem('growwupInfo');
        localStorage.clear();

        setUserData((prevData) => null);

        // reconnect socket 
        channel.postMessage({ status: 'logout' });
        connectSocketAuth({ action: 'reconnectSocket', userData: null })
    }

    const connectSocketAuth = (postData) => {
        // console.log('postData', postData, userData);

        let tempUserId = `temp_${Math.random().toString(36).substring(2, 15)}`;
        if (postData.action === "reconnectSocket") {
            if (postData.userData) {
                tempUserId = postData.userData._id
            }
            reconnectSocket(tempUserId)
            // console.log('connectSocketAuth:reconnectSocket:IF::socket', tempUserId);

        }else if( postData.action==="initializeSocket"){
            if (userData) {
                tempUserId = userData._id
            }
            initializeSocket(tempUserId)
            // console.log('connectSocketAuth:initializeSocket:ELSE::socket', tempUserId);
        }

    }


    const contextValues = {
        userData,
        storeUserDataInContext,
        logoutUser,
        connectSocketAuth
    }
    return (
        <AuthContext.Provider value={contextValues}>
            {children}
        </AuthContext.Provider>
    );
}

channel.onmessage = (event) => {
    if (event.data.status === 'login') {
        console.log('User logged in on another tab.');
        window.location.reload(); // Reload to apply any login changes
    } else if (event.data.status === 'logout') {
        console.log('User logged out on another tab.');
        window.location.reload(); // Reload to apply any logout changes
    }
};