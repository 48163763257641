import Moment from 'moment';

export const getTimeDiffInMinutes = (msgDate_req, serverTime_req ) =>{
    // Input timestamps
    const msgDate = new Date(msgDate_req);
    const serverTime = new Date(serverTime_req);

    // Calculate time difference in milliseconds
    const timeDiffMs = serverTime - msgDate;

    // Convert milliseconds to minutes
    const timeDiffMinutes = Math.floor(timeDiffMs / (1000 * 60));
    return timeDiffMinutes;

} 
export const formateDateTime = (datetime) => {
    return Moment(datetime).format('D MMM YYYY h:mm A')
}

export const formateChatMiddleDateTime = (datetime) => {
    return Moment(datetime).format('D MMMM YYYY')
}


export const getCurrectDateTimeChat = () => {
    return Moment().format('YYYY-MM-DD HH:mm:ss');

}

export const getDateChat = (datetime) => {
    return Moment(datetime).format('YYYY-MM-DD');

}
export const getNameInitials = (name) => {
    if(name===undefined){
        return '-'
    }
    
    // Split the name by spaces
    const nameParts = name.trim().split(" ");

    // Check if there are at least two words
    if (nameParts.length > 1) {
        // Take the first character of the first two words
        return nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase();
    } else {
        // Take the first two characters of the single word
        return nameParts[0].slice(0, 2).toUpperCase();
    }
}