import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { getLastMessageUser, getMessageApi, sendMessageApi, getUserLastMsgApi } from '../../../api/chatsApi';
import { useAuthContext } from '../../../context/AuthContext';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'


import Footer from '../Footer';
import Header from '../../Website/Header';
import { toast } from 'react-toastify';
import { formateDateTime, getNameInitials, getCurrectDateTimeChat, formateChatMiddleDateTime, getDateChat, getTimeDiffInMinutes } from '../../../utils/dateTimeManage';

import { getSocket } from '../../../utils/socket.js'
import { v4 as uuidv4 } from 'uuid';


function Chat() {
    const navigate = useNavigate();
    const [contactList, setContactList] = useState([]);
    const [loadingContactListing, setLoadingContactListing] = useState(false);
    const [hasMoreContact, setHasMoreContact] = useState(true); // Indicates if more data is available
    const [contactCurrentPage, setContactCurrentPage] = useState(1);
    const [contactPerPage] = useState(15);
    const [totalContacts, setTotalContacts] = useState(0)
    const [search, setSearch] = useState('')
    const [finalSearchText, setFinalSearchText] = useState('')

    const [loadingMsgListing, setLoadingMsgListing] = useState(false);
    const { logoutUser } = useAuthContext();

    const [selectedContact, setSelectedContact] = useState(localStorage.getItem('SelectedChatContact') ? JSON.parse(localStorage.getItem('SelectedChatContact')) : null)
    const [messageList, setMessageList] = useState([])
    const [msgPerPage] = useState(10);
    const [textMsg, setTextMsg] = useState('')
    const [isTop, setIsTop] = useState(false)
    const [isChatExpire, setIsChatExpire] = useState(false)

    //scroll    
    const chatContainerRef = useRef(null);
    const contactContainerRef = useRef(null);
    const chatEndRef = useRef(null); // Reference to scroll to the bottom
    const chatDateTime = useRef(''); // Reference to scroll to the bottom
    const msgCurrentPage = useRef(1);
    const isMsgLoading = useRef(false)
    const selectedContactRef = useRef(selectedContact);


    const getLastMessageUserList = async () => {
        setLoadingContactListing(true);
        try {
            let postData = {
                limit: contactPerPage,
                skip: (contactCurrentPage - 1) * contactPerPage,
                // name: finalSearchText
            }
            const responceData = await getLastMessageUser(postData);
            if (responceData?.data.length > 0) {
                setContactList((prevContacts) => [...prevContacts, ...responceData.data]);
            } else {
                setHasMoreContact(false);
            }
            setLoadingContactListing(false)
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Chat list failed';

            }
            setLoadingContactListing(false)
            setHasMoreContact(false);

        }
    }

    const getMessageList = async (loadingReq) => {

        // check scrolling top and message history end then don't hit api again and again
        if (isTop || isMsgLoading.current) {
            return
        }

        // set msg loading true while only scroll request
        isMsgLoading.current = true
        setLoadingMsgListing(true);

        // Record the current scroll height before adding messages
        const previousScrollHeight = chatContainerRef.current.scrollHeight;
        try {
            let postData = {
                limit: msgPerPage,
                skip: (msgCurrentPage.current - 1) * msgPerPage,
                contactId: selectedContact.contactData._id
            }
            const responceData = await getMessageApi(postData);
            if (responceData?.data.length > 0) {
                msgCurrentPage.current += 1
                // let newMessageList = responceData.data.reverse();
                let newMessageList = responceData.data;

                // if no message in list then store first message time
                if(messageList.length ===0){
                    chatDateTime.current = newMessageList[0].updatedAt
                }else{
                    chatDateTime.current = messageList[0].updatedAt
                }
                
                // chatDateTime.current = newMessageList[responceData.data.length-1].updatedAt
                newMessageList.forEach((newMsg, newMsgindex) => {
                    addMessageItem(newMsg, 'getMessageList')
                })

                setTimeout(() => {

                    if (loadingReq === "initial") { //scroll to bottom while intial message loading
                        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        // Adjust the scroll position to maintain the previous visible messages
                        const newScrollHeight = chatContainerRef.current.scrollHeight;
                        chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
                    }
                    //check comes to top
                    let postData = { reqFrom: 'msgListSucess', isTop: false }
                    if (responceData?.data.length < msgPerPage) {
                        postData = { ...postData, isTop: true }
                    }
                    resetDataAfterMessageListing(postData)
                }, 100);

            } else {
                let postData = { reqFrom: 'msgListNoMoreData', isTop: true }
                resetDataAfterMessageListing(postData)
            }
        } catch (error) {
            let postData = { reqFrom: 'msgListCatchBlock', isTop: true }
            resetDataAfterMessageListing(postData)
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'get Message failed';
                toast.error(errorMessage);
            }

        }
    }

    const getUserLastMsg = async () => {
        try {
            let postData = {
                contactId: selectedContact.contactData._id
            }
            const responceData = await getUserLastMsgApi(postData);
            if (responceData?.data) {
                let msgDate = responceData?.data[0]?.msgDate;
                let serverTime = responceData?.serverTime
                const timeDiffMinutes = getTimeDiffInMinutes(msgDate, serverTime);
                if (timeDiffMinutes > 1440) {
                    setIsChatExpire(true)
                } else {
                    setIsChatExpire(false)
                }

                console.log('msgDate', msgDate);
                console.log('serverTime', serverTime);
                console.log('timeDiffMinutes', timeDiffMinutes);


            } else {
                setIsChatExpire(false)
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Last Message failed';

            }
        }
    }

    const addMessageItem = (newMsg, msgFrom) => {
        // calculate scroll position
        let beforeMessageScrollDiff = ((chatContainerRef.current.scrollHeight) - (chatContainerRef.current.scrollTop + chatContainerRef.current.clientHeight))

        let newMsgTime = newMsg.updatedAt
        let postData = {
            newMsgTime: newMsgTime,
            msgFrom: msgFrom,
            beforeMessageScrollDiff: beforeMessageScrollDiff
        }
        checkNewMessageTime(postData)


        let tempMsg = {
            msgType: newMsg.msgType,
            message: newMsg.message,
            updatedAt: newMsgTime,
            sendBy: newMsg.sendBy,
            templateId: newMsg.templateId,
            msgfile: newMsg.msgfile,
            whatsAppMessageId: newMsg.whatsAppMessageId,
            beforeMessageScrollDiff: beforeMessageScrollDiff,

        }

        let msgStatus = handleMessageStatus(newMsg, msgFrom)
        tempMsg = { ...tempMsg, msgStatus: msgStatus }

        if (msgFrom === "getMessageList") {
            setMessageList((prevMsg) => [tempMsg, ...prevMsg]);
        } else if (msgFrom === "sendMsg") {
            setMessageList((prevMsg) => [...prevMsg, tempMsg]);
        } else if (msgFrom === "newMessage") {
            setMessageList((prevMsg) => [...prevMsg, tempMsg]);
        }
    }

    const handleMessageStatus = (newMsg, msgFrom) => {
        let msgStatus = {
            status: 'None'
        }
        if (newMsg.isFailed === 1) {
            msgStatus.status = 'Failed'
            msgStatus.failedReason = 'Message failed to sent to user'

            if (newMsg.errorObject && newMsg.errorObject[0] && newMsg.errorObject[0]?.error_data?.details) {
                msgStatus.failedReason = newMsg.errorObject[0]?.error_data?.details
            }

        } else if (newMsg.isRead === 1) {
            msgStatus.status = 'Read'

        } else if (newMsg.isDelivered === 1) {
            msgStatus.status = 'Delivered'

        } else if (newMsg.isSent === 1) {
            msgStatus.status = 'Sent'

        }
        return msgStatus

    }

    const checkNewMessageTime = (postData) => {
        // console.log('')
        // console.log('')
        console.log('checkNewMessageTime-------------------------------------')
        console.log('checkNewMessageTime: postData', postData);
        console.log('checkNewMessageTime: chatDateTime.current', chatDateTime.current);
        console.log('checkNewMessageTime: postData.newMsgTime', postData.newMsgTime);

        let newMsgTime = postData.newMsgTime
        let msgFrom = postData.msgFrom
        let beforeMessageScrollDiff = postData.beforeMessageScrollDiff

        if (getDateChat(chatDateTime.current) !== getDateChat(newMsgTime)) {
            console.log('checkNewMessageTime: updateing time................................',msgFrom);
            
            let tempMsg = {
                msgType: 'dateTime',
                updatedAt: (msgFrom === "getMessageList")?chatDateTime.current:newMsgTime,
                beforeMessageScrollDiff: beforeMessageScrollDiff
            }
            if (msgFrom === "getMessageList") {
                console.log('checkNewMessageTime: tempMsg', tempMsg);

                setMessageList((prevMsg) => [tempMsg, ...prevMsg]);
            } else if (msgFrom === "sendMsg") {
                setMessageList((prevMsg) => [...prevMsg, tempMsg]);
            } else if (msgFrom === "newMessage") {
                setMessageList((prevMsg) => [...prevMsg, tempMsg]);
            }
            chatDateTime.current = newMsgTime
        }
    }

    const resetDataAfterMessageListing = (postData) => {
        setIsTop(postData.isTop)
        setLoadingMsgListing(false);
        isMsgLoading.current = false
    }

    const sendMsg = async () => {
        // console.log('messageList',messageList);
        let msgListLastMsgDate = messageList[messageList.length-1].updatedAt
        let newMsgDate = getCurrectDateTimeChat()
        console.log('msgListLastMsgDate',msgListLastMsgDate);
        console.log('newMsgDate',newMsgDate);
        
        chatDateTime.current = msgListLastMsgDate
        
        let temptextMsg = textMsg
        let tempWhatsAppMessageId = uuidv4();
        let newMsg = {
            msgType: 'text',
            message: temptextMsg,
            sendBy: 'system',
            templateId: null,
            msgfile: null,
            updatedAt: newMsgDate,
            isSent: 1,
            whatsAppMessageId: tempWhatsAppMessageId
        }
        addMessageItem(newMsg, "sendMsg")
        setTextMsg('')
        // return
        try {
            let postData = new FormData();
            postData.append('contactId', selectedContact.contactData._id,)
            postData.append('messageType', "text")
            postData.append('message', temptextMsg)
            postData.append('tempWhatsAppMessageId', tempWhatsAppMessageId)
            const responseData = await sendMessageApi(postData);
            if (responseData?.data) {
                updateMsgWhatsAppMessageId(responseData)
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'send Message failed';
                toast.error(errorMessage);

                let messageStatusInfo = {
                    statusData: {
                        whatsAppMessageId: tempWhatsAppMessageId,
                        isFailed: 1,
                        errorObject: [
                            {
                                error_data: {
                                    details: errorMessage
                                }
                            }
                        ]
                    }
                }

                handleMessageStatusSocketData(messageStatusInfo)
            }

        }
    }

    const openChat = (contactInfo) => {
        // check this user chat is already opened then no need to again selected
        if (selectedContactRef.current && selectedContactRef.current.contactData._id === contactInfo.contactData._id) {
            return
        }
        localStorage.setItem('SelectedChatContact', JSON.stringify(contactInfo))
        setMessageList([])
        setIsTop((prev) => (false))
        setSelectedContact(contactInfo)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) { // Send on Enter, but not on Shift + Enter
            e.preventDefault(); // Prevent default form submission (if inside a form)
            sendMsg();
        }
    };

    const updateLastItem = () => {
        setMessageList((prevList) => {
            // Create a copy of the list
            const updatedList = [...prevList];

            // Update the last item
            const lastIndex = updatedList.length - 1;
            if (lastIndex >= 0) {
                updatedList[lastIndex] = {
                    ...updatedList[lastIndex],
                    beforeMessageScrollDiff: 500, // Example value
                };
            }

            return updatedList;
        });
    };

    const updateMsgWhatsAppMessageId = (responseData) => {

        let tempWhatsAppMessageId = responseData?.param.tempWhatsAppMessageId
        let newMsg = responseData?.data
        let newWhatsAppMessageId = newMsg.whatsAppMessageId
        setMessageList((prevMsgList) => {
            let messageIndex = prevMsgList.findLastIndex((messageInfo) => {
                return messageInfo.whatsAppMessageId === tempWhatsAppMessageId
            });

            if (messageIndex !== -1) {
                prevMsgList[messageIndex] = {
                    ...prevMsgList[messageIndex],
                    whatsAppMessageId: newWhatsAppMessageId,
                };
            }
            return prevMsgList
        })
    };

    // handle message listing scroll
    const handleScroll = () => {
        if (chatContainerRef.current.scrollTop === 0 && !isMsgLoading.current) {
            updateLastItem();
            getMessageList("scroll");
        }


    }

    // handle contact listing scroll
    const handleContactScroll = () => {
        // calculate scroll position
        let beforeContactScrollDiff = ((contactContainerRef.current.scrollHeight) - (contactContainerRef.current.scrollTop + contactContainerRef.current.clientHeight))
        if (beforeContactScrollDiff < 20 && !loadingContactListing && hasMoreContact) {
            setContactCurrentPage((prev) => prev + 1);
        }
    }

    // Scroll to bottom whenever the message list changes
    useEffect(() => {
        console.log('checkNewMessageTime:messageList', messageList);

        if (messageList.length > 0) {

            let lastMessage = messageList[messageList.length - 1];
            const isAtBottom = lastMessage.beforeMessageScrollDiff <= 200
            if (isAtBottom) {
                // Scroll to the bottom if the user is at the bottom
                chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [messageList]);

    useEffect(() => {
        if (selectedContact) {
            isMsgLoading.current = false
            msgCurrentPage.current = 1
            selectedContactRef.current = selectedContact;
            console.log('selectedContactRef', selectedContactRef);

            getMessageList("initial")
            getUserLastMsg()
        } else {
            setMessageList([])
        }
    }, [selectedContact])

    const handleNewMessageSocketData = (newMsgInfo) => {
        let newMsg = newMsgInfo.data
        if (selectedContactRef.current && selectedContactRef.current.contactData._id === newMsg.contactId) {
            addMessageItem(newMsg, 'newMessage')
        }
    }

    const handleMessageStatusSocketData = (messageStatusInfo) => {
        console.log('messageStatusInfo', messageStatusInfo);

        let newStatusWhatsAppMessageId = messageStatusInfo.statusData.whatsAppMessageId;
        setMessageList((prevMsgList) => {
            let messageIndex = prevMsgList.findLastIndex(messageInfo => {
                return messageInfo.whatsAppMessageId === newStatusWhatsAppMessageId
            })
            if (messageIndex !== -1) {
                let newMsg = messageStatusInfo.statusData;
                let msgStatus = handleMessageStatus(newMsg, "handleMessageStatusSocketData")

                // Efficiently update only the required part
                return [
                    ...prevMsgList.slice(0, messageIndex),
                    { ...prevMsgList[messageIndex], msgStatus },
                    ...prevMsgList.slice(messageIndex + 1),
                ];
            }
            return prevMsgList
        })
    }

    const HandleMessageStatusHtml = ({ msgInfo }) => {
        return (
            <>
                {/* {msgInfo.msgStatus.status} */}
                {msgInfo.msgStatus.status === "Sent" && <Icon.Check size={20} />}
                {msgInfo.msgStatus.status === "Delivered" && <Icon.Check2All size={15} color='gray' />}
                {msgInfo.msgStatus.status === "Read" && <Icon.Check2All size={15} color='blue' />}
                {msgInfo.msgStatus.status === "Failed" && (
                    <>
                        <Icon.ExclamationCircle color='red' size={13}
                            data-tooltip-id={msgInfo.whatsAppMessageId} data-tooltip-content={msgInfo.msgStatus.failedReason}
                        />
                        <Tooltip id={msgInfo.whatsAppMessageId} place='top-end' wrapper="span" />
                    </>

                )}


            </>
        )
    }

    // handle contact listing
    useEffect(() => {
        getLastMessageUserList();
    }, [contactCurrentPage])

    useEffect(() => {
        //handle socket
        let socket = getSocket();
        socket.on("newMessage", (newMsgInfo) => {
            handleNewMessageSocketData(newMsgInfo);
        })

        socket.on("messageStatus", (messageStatus) => {
            handleMessageStatusSocketData(messageStatus);
        })

        return () => {
            socket.off('newMessage')
        }
    }, [])
    return (<>
        <Header />
        <main className="main mainheight">
            <div className="container">
                <h5 className="title">Manage chat</h5>
                <div className=" row top-row" >
                    {/* contact listing start */}
                    <div className='col-12 col-md-3 col-lg-3'>
                        <div className="rightbar-wrap pb-3" >
                            <div className="rightbar">
                                <div className="chatwindow" id="chatwindow">
                                    <div className="card border-0 h-100">
                                        {/* <div className="input-group input-group-md">
                                            <span className="input-group-text text-theme"><i className="bi bi-person-plus"></i></span>
                                            <input type="text" className="form-control" placeholder="Search contact... " />
                                        </div> */}
                                        <div className="card-body h-100 overflow-y-auto p-0"
                                            //style={{ maxHeight: '50px' }}
                                            ref={contactContainerRef}
                                            onScroll={handleContactScroll}
                                        >
                                            <ul className="list-group list-group-flush bg-none rounded-0 ">
                                                {contactList.map((contactInfo, contactIndex) => {

                                                    return (
                                                        <li
                                                            onClick={() => openChat(contactInfo)}
                                                            className={`list-group-item cursor-pointer ${contactInfo?.contactData?._id === selectedContact?.contactData?._id ? 'chat-box-active-contact' : ''}`} key={contactIndex}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <figure className="avatar avatar-44 rounded-circle bg-theme">
                                                                        <span className="h6 vm">{getNameInitials(contactInfo?.contactData?.name)}</span>
                                                                    </figure>
                                                                </div>
                                                                <div className="col-9 align-self-center ps-0">
                                                                    <div className="row g-0">
                                                                        <div className="col-8">
                                                                            <p className="text-truncate mb-0">{contactInfo?.contactData?.name}</p>
                                                                        </div>

                                                                    </div>
                                                                    <p className="text-secondary small text-truncate">
                                                                        {contactInfo?.lastMessage}
                                                                    </p>
                                                                    <p className="text-secondary small text-truncate">
                                                                        {formateDateTime(contactInfo?.msgDate)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>)

                                                })}

                                            </ul>

                                            {loadingContactListing && <div style={{ textAlign: 'center' }}>Loading...</div>}
                                            {!hasMoreContact && <div style={{ textAlign: 'center' }}>No more contacts</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* contact listing end */}

                    {/* message listing start */}
                    {selectedContact ? (
                        <div className="col-12 col-md-8 col-lg-8" style={{ marginLeft: '20px' }}>
                            <div className="card shadow-none border-0">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col align-self-center">
                                            <p className="mb-0">{selectedContact.contactData.name}</p>
                                            <p className="text-secondary small">{formateDateTime(selectedContact.msgDate)}</p>
                                        </div>

                                        {isChatExpire && <div className="col align-self-center ">
                                            <p className="mb-0 error">Chat session has expired. <Icon.QuestionCircle color='red' size={13}
                                                data-tooltip-id={'chatSessionMsg'} data-tooltip-content={' Chat session has expired. To start session again, User has to send message to your business number or you have to send a pre approved template to user number.'}
                                            // data-tooltip-id={'chatSessionMsg'} data-tooltip-content={' Please send a pre approved template to start session again.'}
                                            />
                                                <Tooltip id={'chatSessionMsg'} place='top-end' wrapper="span" />
                                            </p>
                                        </div>}

                                    </div>
                                </div>
                                <div className="card-body overflow-y-auto h-250 chat-list"
                                    ref={chatContainerRef}
                                    onScroll={handleScroll}
                                >
                                    <div className="row no-margin" style={{
                                        position: 'absolute',
                                        left: '45%',
                                        top: '10%',
                                        display: loadingMsgListing ? 'block' : 'none'
                                    }}>
                                        <div className="col-12 text-center">
                                            <span className="alert-warning text-secondary mx-auto btn btn-sm py-1 mb-3">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                    {isTop && <div className="row no-margin">
                                        <div className="col-12 text-center">
                                            <span className="alert-warning text-secondary mx-auto btn btn-sm py-1 mb-3">
                                                <Icon.Clock size={18} className='cursor-pointer' /> Chat End

                                            </span>
                                        </div>
                                    </div>}

                                    {
                                        messageList.map((msgInfo, msgIndex) => {
                                            return (
                                                <Fragment key={msgIndex}>
                                                    {(msgInfo.msgType).toLowerCase() === "datetime" ?
                                                        (<div className="row no-margin text-center">
                                                            <div className="col-1"></div>
                                                            <div className="col-4">
                                                                <hr className="hr-style" />
                                                            </div>
                                                            <div className="col-3">
                                                            <span className="alert-warning text-secondary mx-auto btn btn-sm mb-3">
                                                                    {formateChatMiddleDateTime(msgInfo.updatedAt)}
                                                                </span>
                                                            </div>
                                                            <div className="col-4">
                                                                <hr className="hr-style" />
                                                            </div>
                                                        </div>) :

                                                        (msgInfo.msgType).toLowerCase() === "text" ?
                                                            (<div className={`row no-margin ${msgInfo.sendBy === "customer" ? 'left' : 'right'}-chat`}>
                                                                <div className="col-12">
                                                                    <div className="chat-block">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                {msgInfo.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-secondary small time"><i className="bi bi-check-all text-primary"></i>
                                                                        {formateDateTime(msgInfo.updatedAt) + ' '}
                                                                        {msgInfo.sendBy === "system" && (<HandleMessageStatusHtml msgInfo={msgInfo} />)}
                                                                    </p>
                                                                </div>
                                                            </div>) :

                                                            (msgInfo.msgType).toLowerCase() === "image" ?
                                                                (<div className={`row no-margin ${msgInfo.sendBy === "customer" ? 'left' : 'right'}-chat`}>
                                                                    <div className="col-12">
                                                                        <div className="chat-block">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <img src={msgInfo.msgfile} alt="Selected File Preview" style={{ width: '100%', height: '200px', marginBottom: '10px' }} />
                                                                                    <br />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <p className="text-secondary small time"><i className="bi bi-check-all text-primary"></i>
                                                                            {formateDateTime(msgInfo.updatedAt) + ' '}
                                                                            {msgInfo.sendBy === "system" && (<HandleMessageStatusHtml msgInfo={msgInfo} />)}
                                                                        </p>
                                                                    </div>
                                                                </div>) :

                                                                (msgInfo.msgType).toLowerCase() === "video" ?
                                                                    (<div className={`row no-margin ${msgInfo.sendBy === "customer" ? 'left' : 'right'}-chat`}>
                                                                        <div className="col-12">
                                                                            <div className="chat-block">
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <video controls style={{ width: '200px', height: '200px', marginBottom: '10px' }}>
                                                                                            <source src={msgInfo.msgfile} type="video/mp4" />
                                                                                        </video>
                                                                                        <br />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <p className="text-secondary small time"><i className="bi bi-check-all text-primary"></i>
                                                                                {formateDateTime(msgInfo.updatedAt) + ' '}
                                                                                {msgInfo.sendBy === "system" && (<HandleMessageStatusHtml msgInfo={msgInfo} />)}
                                                                            </p>
                                                                        </div>
                                                                    </div>) :

                                                                    (msgInfo.msgType).toLowerCase() === "document" ?
                                                                        (<div className={`row no-margin ${msgInfo.sendBy === "customer" ? 'left' : 'right'}-chat`}>
                                                                            <div className="col-12">
                                                                                <div className="chat-block">
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <iframe
                                                                                                src={msgInfo.msgfile}
                                                                                                style={{ width: '200px', height: '200px' }}
                                                                                                title="Document Preview"
                                                                                            />
                                                                                            <br />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <p className="text-secondary small time"><i className="bi bi-check-all text-primary"></i>
                                                                                    {formateDateTime(msgInfo.updatedAt) + ' '}
                                                                                    {msgInfo.sendBy === "system" && (<HandleMessageStatusHtml msgInfo={msgInfo} />)}
                                                                                </p>
                                                                            </div>
                                                                        </div>) :

                                                                        (msgInfo.msgType).toLowerCase() === "template" ?
                                                                            (<div className={`row no-margin ${msgInfo.sendBy === "customer" ? 'left' : 'right'}-chat`}>
                                                                                <div className="col-12">
                                                                                    <div className="chat-block">
                                                                                        <div className="row" style={{ maxWidth: '400px' }}>
                                                                                            <div className="col">
                                                                                                {/* {JSON.stringify(msgInfo)} */}

                                                                                                {(msgInfo.templateId.headerType).toLowerCase() == "text" && (
                                                                                                    <><b>{msgInfo.templateId.headerText}</b> <br /><br /></>
                                                                                                )}

                                                                                                {(msgInfo.templateId.headerType).toLowerCase() == "image" && (
                                                                                                    <>
                                                                                                        <img src={msgInfo.templateId.headerFile} alt="Selected File Preview" style={{ width: '100%', height: '200px', marginBottom: '10px' }} />
                                                                                                        <br />
                                                                                                    </>
                                                                                                )}

                                                                                                {(msgInfo.templateId.headerType).toLowerCase() == "video" && (

                                                                                                    <>
                                                                                                        <video controls style={{ width: '200px', height: '200px', marginBottom: '10px' }}>
                                                                                                            <source src={msgInfo.templateId.headerFile} type="video/mp4" />
                                                                                                        </video>
                                                                                                        <br />
                                                                                                    </>
                                                                                                )}

                                                                                                {(msgInfo.templateId.headerType).toLowerCase() == "document" && (
                                                                                                    <>
                                                                                                        <iframe
                                                                                                            src={msgInfo.templateId.headerFile}
                                                                                                            style={{ width: '200px', height: '200px' }}
                                                                                                            title="Document Preview"
                                                                                                        />
                                                                                                        <br />
                                                                                                    </>
                                                                                                )}

                                                                                                {msgInfo.templateId.body}

                                                                                                {msgInfo.templateId.footer && (
                                                                                                    <><br /><br />{msgInfo.templateId.footer} </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <p className="text-secondary small time"><i className="bi bi-check-all text-primary"></i>
                                                                                        {formateDateTime(msgInfo.updatedAt) + ' '}
                                                                                        {msgInfo.sendBy === "system" && (<HandleMessageStatusHtml msgInfo={msgInfo} />)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>) :

                                                                            ''
                                                        // JSON.stringify(msgInfo)
                                                    }

                                                </Fragment>
                                            )
                                        })
                                    }
                                    <div ref={chatEndRef} />

                                </div>
                                <div className="card-footer p-0">
                                    <div className="input-group input-group-md">
                                        <button className="btn btn-sm btn-link px-2"><i className="bi bi-emoji-smile"></i></button>
                                        <button className="btn btn-sm btn-link px-2"><i className="bi bi-paperclip"></i></button>
                                        <input type="text"
                                            className="form-control" placeholder="Type your message... "
                                            value={textMsg}
                                            onChange={(e) => setTextMsg(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <button
                                            onClick={(e) => sendMsg()}

                                            className="btn btn-sm btn-link px-2" type="button">
                                            Send
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    ) : (
                        <div className="col-12 col-md-8 col-lg-8" style={{ marginLeft: '20px' }}>
                            <div className="card shadow-none border-0">
                                <div className="card-body overflow-y-auto h-250 chat-list">
                                    Select Contact to view chat..

                                </div>
                            </div>
                        </div>
                    )}
                    {/* message listing end */}

                </div>

            </div >

        </main >
        <Footer />

    </>)
}

export default Chat;